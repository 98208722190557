<template>
  <v-dialog v-model="dialog" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="formData.status"
                :items="status"
                dense
                chips
                small-chips
                label="product status"
                item-text="title"
                item-value="value"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="formData.code"
                :rules="textRules"
                label="code"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :rules="vNumberRules"
                v-model="formData.user_max_num"
                label="max num of user"
                required
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <v-text-field
                :rules="vNumberRules"
                v-model="formData.one_user_useage"
                label="max num of one user useage"
                required
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="formData.persentage"
                :rules="rNumberRules"
                label="persentage"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="formData.value"
                :rules="rNumberRules"
                label="value"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                :loading="loadingCategories"
                v-model="formData.category_id"
                :items="categories"
                dense
                chips
                small-chips
                label="categories"
                item-text="arabic_title"
                item-value="id"
                @change="getProducts"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                :loading="loadingTags"
                v-model="formData.tag_id"
                :items="tags"
                dense
                chips
                small-chips
                label="tags"
                item-text="arabic_title"
                item-value="id"
                @change="getProducts"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                :loading="loadingProducts"
                v-model="formData.product_id"
                :items="products"
                dense
                chips
                small-chips
                label="products"
                item-text="arabic_title"
                item-value="id"
              ></v-autocomplete>
            </v-col>

            <v-col md="6">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.start_date"
                    label="Picker without buttons"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.start_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col md="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.exp_date"
                    label="Picker without buttons"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.exp_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="6">
              <v-checkbox
                v-model="formData.free_shipping"
                :label="`free shipping: ${formData.free_shipping.toString()}`"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["formTitle", "record", "dialog", "items", "collection"],
  data() {
    return {
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      menu1: false,
      menu2: false,
      loadingCategories: false,
      loadingTags: false,
      loadingProducts: false,
      status: [
        { title: "Published", value: "published" },
        { title: "Draft", value: "draft" },
      ],
      formData: {
        id: null,
        code: null,
        product_id: null,
        category_id: null,
        tag_id: null,
        user_max_num: 0,
        one_user_useage: 1,
        start_date: null,
        exp_date: null,
        persentage: 0,
        value: 0,
        free_shipping: false,
        status: null,
      },
      numberRules: [
        (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
        // (v) => v > 0 || "The value must be greater than zero",
      ],
      categories: [],
      products: [],
      tags: [],
      textRules: [(v) => !!v || "field is required"],
      vNumberRules: [
        (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
        (v) => v > 0 || "The value must be greater than zero",
      ],
      rNumberRules: [
        (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
        (v) => !!v || "field is required",
      ],
      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async submit() {
      // console.log(this.background_color);
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          console.log(this.formData);
          if (this.record) {
            await ApiService.update(
              `items/${this.collection}`,
              this.formData.id,
              this.formData
            );
          } else {
            await ApiService.post(`items/${this.collection}`, this.formData);
          }
          this.submitted = false;
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },

    async getProducts() {
      try {
        let url = `items/products?fields=id,arabic_title`;
        if (this.formData.category_id) {
          url =
            url +
            `&filter[categories.category_id]=${this.formData.category_id}`;
        }
        if (this.formData.tag_id) {
          url = url + `&filter[tags.tag_id]=${this.formData.tag_id}`;
        }
        this.loadingProducts = true;
        const { data } = await ApiService.get(url);
        this.products = data.data;
        this.loadingProducts = false;
      } catch (error) {
        console.log(error);
      }
    },

    async getCategories() {
      try {
        this.loadingCategories = true;
        const categories = await ApiService.get(
          "items/categories?fields=id,arabic_title,child_categories.id"
        );
        categories.data.data.forEach((element) => {
          if (element.child_categories.length == 0) {
            this.categories.push(element);
          }
        });
        this.loadingCategories = false;
      } catch (error) {
        console.log(error);
      }
    },

    async getTags() {
      try {
        this.loadingTags = true;
        const { data } = await ApiService.get(
          "items/tags?fields=id,arabic_title"
        );
        this.tags = data.data;
        this.loadingTags = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    await this.getCategories();
    await this.getTags();
    if (this.record) {
      Object.keys(this.formData).forEach((element) => {
        if (this.record[element]) {
          if (typeof this.record[element] == "object") {
            this.formData[element] = this.record[element].id;
          } else {
            this.formData[element] = this.record[element];
          }
        }
      });
      await this.getProducts();
      this.formData.product_id = this.record.product_id.id;
    }
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
